<template>
  <div class="news">
    <img :src="banner" class="banner" />
    <div class="main">
      <div class="t-a-c title fs24 bold">新闻动态</div>
      <div class="list">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="alCen list-item hand"
          @click="toNews(item.id)"
        >
          <div class="one-overflow list-item-title">
            {{ item.title }}
          </div>
          <div class="maLfAuto">[ 新闻动态 ]</div>
          <div class="maLf20">
            {{
              item.createTime.length == 10
                ? item.createTime
                : item.createTime.substring(0, 10)
            }}
          </div>
        </div>
      </div>
      <pagination
        class="pagination"
        @currentChange="currentChange"
        :total="Number(totalpages)"
      />
    </div>
  </div>
</template>

<script>
import Pagination from "../../components/pagination.vue";
export default {
  name: "news",
  components: {
    Pagination,
  },
  data() {
    return {
      banner: require("../../assets/image/scheme/1.png"),
      list: [
        {
          title: "胡春华强调 巩固拓展脱贫攻坚成果 加快全面推进乡村振兴",
          createTime: "2022-01-29",
        },
        {
          title: "为乡村振兴战略“铺路架桥”",
          createTime: "2022-01-29",
        },
        {
          title:
            "《“十四五”国家信息化规划》专家谈：打造智慧应急 推进应急管理现代化",
          createTime: "2022-01-29",
        },
        {
          title: "无人拖拉机、采摘机器人……佛山这个智慧农业园区值得一学！",
          createTime: "2022-01-29",
        },
        {
          title:
            "广东省人民政府办公厅关于印发广东省数字政府改革建设2022年工作要点的通知",
          createTime: "2022-01-29",
        },
        {
          title: "推进数字乡村建设 助力乡村振兴",
          createTime: "2022-01-29",
        },
        {
          title: "广东明确！鼓励宅基地发展农家乐、民宿、乡村旅游",
          createTime: "2022-01-29",
        },
        {
          title:
            "李克强在政府工作报告中提出：今年大力抓好农业生产，促进乡村全面振兴！",
          createTime: "2022-01-29",
        },
        {
          title: "「两会特刊」全国政协委员马传喜：数字乡村建设步伐加快",
          createTime: "2022-01-29",
        },
      ],
      totalpages: 1,
    };
  },
  watch: {},
  created() {
    this.getNewsList();
  },
  methods: {
    toNews(id) {
      let { href } = this.$router.resolve({
        path: "../article",
        query: {
          id,
        },
      });
      window.open(href, "_blank");
    },
    currentChange(v) {
      this.getNewsList(v);
    },
    // 获取新闻资讯列表
    getNewsList(pageNum = 1) {
      this.request({
        url: this.$api.getNewsList,
        data: {
          pageNum,
          pageSize: 10,
          searchKey: "",
        },
      }).then((res) => {
        console.log(res, "新闻");
        if (res.code == 0) {
          this.list = res.data.list;
          this.totalpages = res.data.totalpages;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import url("./news.less");
</style>
