<template>
  <div class="pagination">
    <el-pagination
      layout="prev, pager, next"
      :page-count="total"
      prev-text="上一页"
      next-text="下一页"
      @current-change="currentChange"
    />
  </div>
</template>

<script>
export default {
  name: "pagination",
  components: {},
  props: {
    total: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {};
  },
  watch: {},
  methods: {
    currentChange(page) {
      this.$emit("currentChange", page);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-pager li.active {
  color: #c40000 !important;
}
/deep/.el-pagination button:disabled {
  color: #c0c4cc !important;
}
/deep/.el-pager li:hover {
  color: #c40000 !important;
}
</style>
